<template>
  <AView
    :value="value"
    :active="question.id === activeQuestionId"
    :name="question.name"
    ref="question-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, question })"
  >
    <template v-slot:content="{}">
      <v-card class="transparent mt-4 pa-2 elevation-0">
        <v-card-text>
          <QuestionEditorVue
            ref="form"
            v-model="question"
            :permissions="value.permissions"
            @onLinkCreate="onLinkCreate"
            @onLinkRemove="onLinkRemove"
            @action="(action) => action.handler({ from: value, question })"
          ></QuestionEditorVue>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
    
    
<script>
import { mapGetters, mapState } from "vuex";
import QuestionEditorVue from "../../../../../../components/wad/organisms/knowledgeBase/questions/forms/editor/QuestionEditor.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    QuestionEditorVue,
  },
  data() {
    return {
      updatedTimeout: undefined,

      question: {
        searchableKeyword: {},
      },
    };
  },
  computed: {
    ...mapState("QuestionStore", ["activeQuestionId", "displayedQuestions"]),
    ...mapGetters("QuestionStore", ["questionById"]),
  },
  created() {
    this.$store.dispatch("QuestionStore/GetQuestion", {
      id: this.value.relation.params.questionId,
    });

    this.question = this.questionById(this.value.relation.params.questionId);
  },
  methods: {
    onHover() {
      this.$store.commit(
        "QuestionStore/setActiveId",
        this.value.relation.params.questionId
      );
    },
    async onLinkCreate(link) {
      const newLink = await this.$store.dispatch(
        "KnowledgeBaseStore/CreateLink",
        {
          link: { link },
        }
      );

      this.question.links.push({ link: newLink });
    },
    async onLinkRemove(link) {
      this.question.links = this.question.links.filter(
        (el) => el.link_id !== link.id
      );
    },
  },

  watch: {
    displayedQuestions: {
      async handler(newVal) {
        if (newVal) {
          this.question = this.questionById(
            this.value.relation.params.questionId
          );
        }
      },
      deep: true,
    },
    question: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("QuestionStore/UpdateQuestion", {
            question: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>