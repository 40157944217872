<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <HelpFormInputVue
      dark
      v-model="form.name"
      :rules="nameRules"
      label="Question"
      :helpText="'Provide a name of the term. Please make sure that it is unique across the system'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      v-model="form.searchableKeyword.name"
      :rules="nameRules"
      label="Searchable Keyword"
      :helpText="'This text will be used to adjust a search in the right way. E.g. this data will be used in Leads-Analyzer'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.description"
      :rules="descriptionRules"
      label="Description"
      :helpText="'The description of the new term'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.goal"
      label="Question Goal"
      :helpText="'Please define a transparent goal of this question. How to understand an answer'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <!-- Tags -->
    <FormSection
      class="mt-0"
      underline
      right
      :label="'Tags'"
      :icon="'mdi-tag-multiple-outline'"
      :actions="tagActions"
      :editable="!!tagActions.length"
      @action="(action) => $emit('action', action)"
    ></FormSection>

    <SelectorKnowledgeBaseTagsVue
      dark
      autoload
      label="Tags"
      helpText="This is an actual Service Status"
      return-object
      v-model="tags"
      multiple
    >
    </SelectorKnowledgeBaseTagsVue>

    <!-- Useful Links -->

    <FormSection
      :label="'Useful Links'"
      class="mt-0"
      :icon="'mdi-link-variant'"
      :editable="false"
      underline
      right
    ></FormSection>

    <HelpFormInputVue
      dark
      class="mt-4"
      outlined
      v-model="newLink"
      :rules="linkRules"
      label="New Link"
      :helpText="'This text will be used to adjust a search in the right way. E.g. this data will be used in Leads-Analyzer'"
      required
      @onEnter="onNewLinkCreate"
    ></HelpFormInputVue>
    <LinksList
      v-if="form.links && form.links.length > 0"
      :value="form.links"
      @onLinkRemove="onLinkRemove(link)"
    ></LinksList>

    <!-- Question Answers -->
    <FormSliderVue
      :add-text="'Add new Answer'"
      v-model="form.answers"
      @onAdd="onAddAnswer"
      @onRemove="onRemoveAnswer"
    >
      <template v-slot:form="{ index }">
        <AnswerForm v-model="form.answers[index]"></AnswerForm>
      </template>
    </FormSliderVue>
  </v-form>
</template>
      
      
<script>
import SelectorKnowledgeBaseTagsVue from "../../../../../atoms/common/autocompletes/SelectorKnowledgeBaseTags.vue";
import FormSection from "../../../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../../../atoms/common/inputs/HelpFormInput.vue";
import LinksList from "../../../../knowledgeBase/links/LinksList.vue";
import _ from "lodash";
import { PermissionsHelper } from "../../../../../../helpers/permissions.helper";
import { TAGS_BUTTONS } from "../../../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import FormSliderVue from "../../../../../atoms/common/FormSlider.vue";
import AnswerForm from "./AnswerForm.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    onLinkCreate: {
      type: Function,
      default: async () => {},
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tagActions: function () {
      return PermissionsHelper.getActions(
        [TAGS_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
  },
  data() {
    const { tags } = this.prepareLists(this.value);

    return {
      // Form
      pValid: this.valid,
      form: this.value,

      tags,
      newLink: "",

      // ======rules
      nameRules: [(v) => !!v || "Term Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],

      linkRules: [
        (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "Link should be a proper link",
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorKnowledgeBaseTagsVue,
    FormSection,
    LinksList,
    FormSliderVue,
    AnswerForm,
  },
  methods: {
    prepareLists(value) {
      let tags = [];

      if (value.tags && value.tags.length) tags = value.tags.map((t) => t.tag);

      return { tags };
    },

    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onNewLinkCreate() {
      this.$emit("onLinkCreate", this.newLink);
    },
    onLinkRemove(link) {
      this.$emit("onLinkRemove", link);
    },
    onAddAnswer() {
      if (this.form.answers.length)
        this.form.answers.splice(0, 0, {
          name: "New  Answer",
          searchableKeyword: {
            name: "New Answer",
          },
        });
      else
        this.form.answers.push({
          name: "New Answer",
          searchableKeyword: {
            name: "New Answer",
          },
        });
    },
    onRemoveAnswer(answer) {
      const answerIndex = this.form.answers.findIndex(
        (ans) => ans.id === answer.id
      );
      this.form.answers.splice(answerIndex, 1);
    },
  },
  watch: {
    value: {
      handler(newVal) {
        const { tags } = this.prepareLists(newVal);

        this.tags = Object.assign(this.tags, tags);

        this.newLink = "";

        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
    tags: {
      handler(newVal) {
        this.form.tags = newVal.map((tag, i, arr) => {
          const existed = _.find(arr, (t) => t.tag_id === tag.id);

          return existed
            ? existed
            : {
                tag,
              };
        });
      },
      deep: true,
    },
  },
};
</script>
      
      
<style lang="scss" scoped>
.term-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>