<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <HelpFormInputVue
      dark
      v-model="form.name"
      :rules="nameRules"
      label="Answer"
      :helpText="'Provide a name of the term. Please make sure that it is unique across the system'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      v-if="form.searchableKeyword"
      v-model="form.searchableKeyword.name"
      :rules="nameRules"
      label="Searchable Keyword"
      :helpText="'This text will be used to adjust a search in the right way. E.g. this data will be used in Leads-Analyzer'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.description"
      :rules="descriptionRules"
      label="Description"
      :helpText="'The description of the answer. Please provide meaningful description what this answer means.'"
      :textarea="true"
      required
    ></HelpFormInputVue>
    <NumberInput
      dark
      v-model="form.complexity_weight"
      :min="0"
      :step="0.1"
      :max="10"
      :helpText="'This is an answer weight that will be used to calculate answer impact.'"
    ></NumberInput>
  </v-form>
</template>
        
        
  <script>
import HelpFormInputVue from "../../../../../atoms/common/inputs/HelpFormInput.vue";
import { PermissionsHelper } from "../../../../../../helpers/permissions.helper";
import { TAGS_BUTTONS } from "../../../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import NumberInput from "../../../../../atoms/common/NumberInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    onLinkCreate: {
      type: Function,
      default: async () => {},
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tagActions: function () {
      return PermissionsHelper.getActions(
        [TAGS_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Answer name is required"],
      descriptionRules: [(v) => !!v || "Answer description is required"],
    };
  },
  components: {
    HelpFormInputVue,
    NumberInput,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
        
        
  <style lang="scss" scoped>
.term-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>